import { ApiService } from './ApiService';
import apiResponse from './ApiResponse';

export class TwilioUserService {
  constructor(private readonly api: ApiService = new ApiService()) {}

  public async Write(email: string) {
    try {
      // Ensure the email is always lowercase - Twilio treats uppercase and lowercase as different users
      const emailToUse = email.toLowerCase();
      const response = await this.api.post('/user/twilio', {
        identity: emailToUse,
      });
      return apiResponse.Success(response);
    } catch (e) {
      return apiResponse.HandleError(e);
    }
  }
}
